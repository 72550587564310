export default {
      application: require('./components/application/dist.index.js').default,
layout: require('./components/layout/dist.index.js').default,
flex: require('./components/flex/dist.index.js').default,
slider: require('./components/slider/dist.index.js').default,
text: require('./components/text/dist.index.js').default,
image: require('./components/image/dist.index.js').default,
icon: require('./components/icon/dist.index.js').default,
form: require('./components/form/dist.index.js').default,
input: require('./components/input/dist.index.js').default,
button: require('./components/button/dist.index.js').default,
cart: require('./components/cart/dist.index.js').default
    }